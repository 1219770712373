import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import * as echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css';
import http from './http'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Statistic from 'element-ui'
import VueSignaturePad from 'vue-signature-pad'
import QRCode from 'qrcode';
// import VueLazyload from 'vue-lazyload';
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Statistic);
Vue.use(VueAxios, axios)
Vue.use(VueSignaturePad)
Vue.prototype.$axios=http;
Vue.prototype.$QRCode = QRCode;
Vue.prototype.$echarts = echarts;
// Vue.prototype.$axios = axios;
axios.defaults.baseURL='/api'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
