/* eslint-disable no-unused-vars */
import Vue from "vue";
import axios from "axios";
import { Base64 } from "js-base64";
import qs from "qs";
import { Loading,Message,MessageBox } from "element-ui";
import router from "./router";
/* eslint-enable no-unused-vars */
const http = axios.create({
  // baseURL, //  process.env.API_URL
  timeout: 1800000,
  transformRequest: [
    function (data) {
      //在向服务器发送前，修改请求数据
      // data = qs.stringify(data)
      data = JSON.stringify(data);
      return data;
    },
  ],
});

// 请求拦截器
// http.interceptors.request.use(
//   (config) => {
//     let token = sessionStorage.getItem("SET_TOKEN");
//     if (config.method == "get") {
//       if (!config.params.noToken) {
//         // if (token) {
//         //   config.headers.Authorization = token;
//         // }
//       }
//       // delete config.params.noToken;

    
//     } else if (config.method == "post" || config.method == "put") {
     
//     } else if (config.method == "delete") {
      
//     }else if(config.method == "upload"){

//     }

//     // 开发环境下，如果请求是 post,put,patch,则打印数据体，方便调试
//     if (process.env.NODE_ENV === "development") {
//       const { method } = config;
//       if (method === "post" || method === "put" || method === "patch") {
//       }
//     }
//     return config;
//   },
//   (error) => {
//     Message.error({
//       message: "请求失败",
//     });
//     return Promise.reject(error);
//   }
// );
// http.interceptors.response.use(
//   (res) => {
   
//     return res;
//   },
//   (error) => {
  
//     return Promise.reject(error);
    
//   }
// );

//封装get接口
// params={} 是设置默认值
function get(url, params = {}) {
  // params.noToken = params.noToken || "";
  return http({
    url: url,
    method: "get",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    params,
  });
}
//封装get接口 用于处理blob数据流
// params={} 是设置默认值

// 处理blob数据流 get
function getBlob(url, params = {}) {
  // params.noToken = params.noToken || "";
  return http({
    url: url,
    method: "get",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    params,
    responseType: "blob",
  });
}
// 处理blob数据流 post
function postBlob(url, data = {}) {
  // params.noToken = params.noToken || "";
  return http({
    url: url,
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
    responseType: "blob",
  });
}
// 上传文件
function upload(url, data = {}) {
  return http({
    url: url,
    method: "post",
    headers: {
      // "Content-Type": "multipart/form-data;multipart/form-data;boundary=aBoundaryString",
      "Content-Type":"multipart/form-data;"
    },
    data,
    // contentType: false,
  });
}
//封装post请求
function post(url, data = {}) {
  //默认配sendObject置
  // data.noToken = data.noToken || "";
  // data.ifAuthoritySystem = data.ifAuthoritySystem || '';
  let sendObject = {
    url: url,
    method: "post",
    headers: {
      // Accept:'application/json',
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: data,
  };
  // sendObject.data = JSON.stringify(data);
  return http(sendObject);
}
//封装put请求
function put(url, data = {}) {
  //默认配置
  // data.noToken = data.noToken || "";
  let sendObject = {
    url: url,
    method: "put",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: data,
  };
  // sendObject.data = JSON.stringify(data);
  return http(sendObject);
}
//封装delet请求
function delet(url, data = {}) {
  //默认配置
  let token = sessionStorage.getItem("SET_TOKEN");
  return http({
    url: url,
    method: "delete",
    headers: {
      Authorization: token,
    },
    data: data,
  });
}
export default {
  get,
  post,
  put,
  delet,
  getBlob,
  postBlob,
  upload,
};
