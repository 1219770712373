import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView'
// import AboutView from '../views/AboutView'

Vue.use(VueRouter)


const routes = [
 
  {
    path: '/',
    name: 'HomeView',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
  },
  {
    path: '/WelcomeWord',
    name: 'WelcomeWord',
    component: () => import(/* webpackChunkName: "about" */ '../views/WelcomeWord.vue'),
  },
  {
    path: '/Information',
    name: 'Information',
    component: () => import(/* webpackChunkName: "about" */ '../views/Information.vue'),
  },
  {
    path: '/Download',
    name: 'Download',
    component: () => import(/* webpackChunkName: "about" */ '../views/Download.vue'),
  },
  {
    path: '/Shedule',
    name: 'Shedule',
    component: () => import(/* webpackChunkName: "about" */ '../views/Shedule.vue'),
  },
  {
    path: '/Registration',
    name: 'Registration',
    component: () => import(/* webpackChunkName: "about" */ '../views/Registration.vue'),
  },
  {
    path: '/Transportation',
    name: 'Transportation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Transportation.vue'),
  },
  {
    path: '/VideoLiveStreaming',
    name: 'VideoLiveStreaming',
    component: () => import(/* webpackChunkName: "about" */ '../views/VideoLiveStreaming.vue'),
  },
  {
    path: '/PhotoLiveStreaming',
    name: 'PhotoLiveStreaming',
    component: () => import(/* webpackChunkName: "about" */ '../views/PhotoLiveStreaming.vue'),
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs.vue'),
  },
  {
    path: '/QRCodeScanner',
    name: 'QRCodeScanner',
    component: () => import(/* webpackChunkName: "about" */ '../views/QRCodeScanner.vue'),
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
